import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/blog-page.scss"
import RecentBlogs from "../components/RecentBlogs"

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div className="section">
        <div className="outer-container">
          <div className="inner-container">
            <div className="blog-container">
              <div className="blog-content">
                {post.frontmatter.imageURL && (
                  <img
                    src={post.frontmatter.imageURL}
                    // alt={post.frontmatter.imageAltText}
                  />
                )}
                <h1>{post.frontmatter.title}</h1>
                <p>
                  by {post.frontmatter.author} | {post.frontmatter.date}
                </p>
                <p>{post.frontmatter.date}</p>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
              </div>
              <div className="recent-blogs-container">
                <RecentBlogs />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogPostTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        imageURL
        author
        tags
      }
      fields {
        slug
      }
    }
  }
`
