import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Link } from "gatsby"
import "../styles/blog-page.scss"

const RecentBlogs = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 5
        ) {
          edges {
            node {
              frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")

                imageURL
              }
              fields {
                slug
              }
              excerpt
            }
          }
        }
      }
    `}
    render={data => (
      <div className="outer-container">
        <h2>Recent Blogs</h2>
        <div className="blog-list">
          <ul>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <li key={node.fields.slug} className="blog-preview">
                <p>
                  <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )}
  />
)

export default RecentBlogs
